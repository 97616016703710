<template>
<div>
<div class="menu" v-if="user">
    <p><a @click="toggleUpload"><i class="fas fa-regular fa-comment"></i></a></p>
    <h2>{{user.firstName}} {{user.lastName}} <a @click="logout"><i class="fas fa-sign-out-alt"></i></a></h2>
    <comment :show="show" :photo="photo" @close="close" @uploadFinished="uploadFinished" />
  </div>
    <div class="focus-image">
        <img :src="photo.path" />
        <div class="photoInfo">
        <p class="photoTitle">{{photo.title}}</p>
        <p class="photoName">{{photo.user.firstName}} {{photo.user.lastName}}</p>
        </div>
        <p class="photoDescription">{{photo.description}}</p>
        <p class="photoDate">{{formatDate(photo.created)}}</p>
    </div>
    <div v-for="comment in comments" :key="comment._id" class="comment">
        <p class="photoDescription commentBox">{{comment.description}}</p>
        <p class="photoName right">{{comment.user.firstName}} {{comment.user.lastName}}</p>
        <p class="photoDate right">{{formatDate(photo.created)}}</p>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Comment from '@/components/Comment.vue';
export default {
  name: 'Photo',
  components: {
    Comment
  },
  data() {
    return {
        photo: {},
        comments: [],
        show: false,
        error: ''
    }
  },
  async created() {
    this.getPhoto();
    this.getComments();
  },
  computed: {
    user() {
      return this.$root.$data.user;
    },
  },
  methods: {
    async getPhoto(){
      try {
        let response = await axios.get("/api/photos/" + this.$route.params.id);
        this.photo = response.data[0];
      } catch (error) {
        this.error = error.response.data.message;
      }
    },
    async getComments(){
      try {
        console.log("/api/comment/" + this.$route.params.id);
        let response = await axios.get("/api/comment/" + this.$route.params.id);
        this.comments = response.data;
        console.log(response);
      } catch (error) {
        this.error = error.response.data.message;
      }
    },
    formatDate(date) {
      if (moment(date).diff(Date.now(), 'days') < 15)
        return moment(date).fromNow();
      else
        return moment(date).format('d MMMM YYYY');
    },
    async logout() {
      try {
        await axios.delete("/api/users");
        this.$root.$data.user = null;
      } catch (error) {
        this.$root.$data.user = null;
      }
    },
    close() {
      this.show = false;
    },
    toggleUpload() {
      this.show = true;
    },
    async uploadFinished() {
      this.show = false;
      this.getComments();
    },
  }
}
</script>

<style scoped>
.photoInfo {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
}

.photoInfo p {
  margin: 0px;
}

.photoDate {
  font-size: 0.7em;
  font-weight: normal;
}

p {
  margin: 0px;
}


.focus-image {
  margin-top: 7em;
  display: inline-block;
  width: 100%;
}

.focus-image img {
  width: 100%;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  padding-top: 1.5em;
}


.menu h2 {
  font-size: 14px;
  margin: 0;
}

.comment {
display: flex;
flex-flow: column;
}

.commentBox {
    border: 1px solid #ccc;
    border-radius: .5em;
}

.right {
 align-self: end;
}
</style>